import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import es from '../assets/sso.PNG';
import tg from '../assets/tge.PNG';
import tw from '../assets/two.PNG';
import dx from '../assets/dxo.PNG';
import ju from '../assets/jut.PNG';
// import cmc from '../assets/cmc.png';
// import cg from '../assets/cg.png';
import audioFile from '../assets/audio.mp3';
import top from '../assets/topg.gif';

import '../App.css';

function App() {
  const [modalVisible, setModalVisible] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);

  // Use useEffect to show the modal when the component mounts
  useEffect(() => {
    setModalVisible(true);
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setAudioPlaying(true);
  };

  const [copied, setCopied] = useState(false);

  const textToCopy = "3BeJ9zCgQhaqKMu2HgKJ79yQBChD1Pf3hPwRX44fpump";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Show message for 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className="App">
      <div className='welcome'>
        <Modal show={modalVisible} onClose={closeModal} />
      </div>
      {audioPlaying && (
      <audio autoPlay loop>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      )}

      <body>
        <div className="contract" onClick={copyToClipboard}>
          {textToCopy}
        </div>
        {copied && <p className="copied-message">CA copied to clipboard</p>}
        <img src={top} alt="logo" className="big"/>
        <h1 className='title'>Cheeseball the Wizard</h1>
        <h2 className='subtitle'>Fat cat takeover</h2>
          <div className='container'>
            <a href='https://solscan.io/token/3BeJ9zCgQhaqKMu2HgKJ79yQBChD1Pf3hPwRX44fpump' className='item'>
              <img src={es} alt='solscan icon' className='icon'/>
            </a>
            <a href='https://dexscreener.com/solana/ekawjsixmtai5vrr3fpra69c7d8tt6xd4rtlb3kxaqpp' className='item'>
              <img src={dx} alt='dexscreener icon' className='icon'/>
            </a>
            <a href='https://x.com/Cheeseball_sol' className='item'>
              <img src={tw} alt='x icon' className='icon'/>
            </a>
            <a href='https://t.me/cheeseballsol' className='item'>
              <img src={tg} alt='telegram icon' className='icon'/>
            </a>
            <a href='https://jup.ag/swap/SOL-3BeJ9zCgQhaqKMu2HgKJ79yQBChD1Pf3hPwRX44fpump' className='item'>
              <img src={ju} alt='jupiter icon' className='icon'/>
            </a>
          </div>
    </body>
  </div>
  );
}

export default App;
